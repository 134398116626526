import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//工具类
import './utils/mixin.js'
import './assets/fonts/fonts.css'
// import './utils/rem.js'


Vue.use(ElementUI);

Vue.component('slideshow', () => import("./components/slideshow.vue"));
Vue.component('leftFloat', () => import("./components/leftFloat.vue"));
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
