import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//首页
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  //玩转条子泥>精品活动>查看更多
  {
    path: "/viewMore",
    name: "viewMore",
    component: () => import("../views/viewMore.vue"),
  },
  //在线预定
  {
    path: "/scenicService",
    name: "scenicService",
    component: () => import("../views/scenicService.vue"),
  },
  //条子泥风光查看更多
  {
    path: "/sceneryMore",
    name: "SceneryMore",
    component: () => import("../views/sceneryMore.vue"),
  },
  //条子泥风光精美视频查看更多
  {
    path: "/videoMore",
    name: "VideoMore",
    component: () => import("../views/videoMore.vue"),
  },
  //精美图片详情
  {
    path: "/scenerydetails",
    name: "SceneryDetails",
    component: () => import("../views/details.vue"),
  },
  {
    path: "/virtual720",
    name: "Virtual720",
    component: () => import("../views/720/virtual720.vue"),
  },
  // 走进条子泥
  {
    path: "",
    component: { render: (e) => e("router-view") },
    redirect: "Dynamic",
    children: [
      //条子泥动态
      {
        path: "/dynamic",
        name: "Dynamic",
        component: () => import("../views/dynamic/index.vue"),
      },
      //条子泥印象
      {
        path: "/about",
        name: "About",
        component: () => import("../views/dynamic/About.vue"),
      },
      // 条子泥风光
      {
        path: "/scenery",
        name: "Scenery",
        component: () => import("../views/dynamic/scenery.vue"),
      },
      // 景区新闻
      {
        path: "/scenicNews",
        name: "scenicNews",
        component: () => import("../views/dynamic/scenicNews.vue"),
      },
      // 景区新闻详情
      {
        path: "/scenicDetails",
        name: "scenicDetails",
        component: () => import("../views/dynamic/scenicDetails.vue"),
      },
    ],
  },
  // 条子泥攻略
  {
    path: "",
    component: { render: (e) => e("router-view") },
    redirect: "foodList",
    children: [
      //基本信息
      {
        path: "/essential",
        name: "essential",
        component: () => import("../views/strategy/essential.vue"),
      },
      // 舌尖美味
      {
        path: "/foodList",
        name: "FoodList",
        component: () => import("../views/strategy/foodList.vue"),
      },
      //周边住宿
      {
        path: "/scenicSpot",
        name: "scenicSpot",
        component: () => import("../views/strategy/scenicSpot.vue"),
      },
      // 景区互动
      {
        path: "/interaction",
        name: "Interaction",
        component: () => import("../views/strategy/interaction.vue"),
      },
      // 行程推荐
      {
        path: "/travel",
        name: "Travel",
        component: () => import("../views/strategy/travel.vue"),
      },
    ],
  },
  //玩转条子泥
  {
    path: "",
    component: { render: (e) => e("router-view") },
    redirect: "allYearActivity",
    children: [
      //全年活动
      {
        path: "/allYearActivity",
        name: "allYearActivity",
        component: () => import("../views/playhard/allYearActivity.vue"),
      },
      //精品活动
      {
        path: "/goodsActivity",
        component: () => import("../views/playhard/goodsActivity.vue"),
      },
    ],
  },
  //语言切换
  {
    path: "",
    component: { render: (e) => e("router-view") },
    children: [
      //英语
      {
        path: "/english",
        meta: {
          keepAlive: true,
        },
        component: () => import("../views/language/English.vue"),
      },
      //韩语
      {
        path: "/Korean",
        meta: {
          keepAlive: true,
        },
        component: () => import("../views/language/Korean.vue"),
      },
      //日语
      {
        path: "/Japanese",
        meta: {
          keepAlive: true,
        },
        component: () => import("../views/language/Japanese.vue"),
      },
    ],
  },
];
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      console.log(to.query.name);
      switch (to.query.name) {
        case "Introduction":
          resolve({ x: 0, y: 600 });
          break;
        case "ScenicSpot":
          resolve({ x: 0, y: 1600 });
          break;
        case "Pictures":
          resolve({ x: 0, y: 2800 });
          break;
        case "Videos":
          resolve({ x: 0, y: 4000 });
          break;
        case "Review":
          resolve({ x: 0, y: 4970 });
          break;
        case "Traffic":
          resolve({ x: 0, y: 8220 });
          break;
        case "TicketandService":
          resolve({ x: 0, y: 9180 });
          break;
        case "景区服务":
          resolve({ x: 0, y: 800 });
          break;
        case "周边服务":
          resolve({ x: 0, y: 2000 });
          break;

        default:
          // setTimeout(() => {
          resolve({ x: 0, y: 0 });

          break;
      }
    });
  },
});

export default router;
