import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeNum: Number(sessionStorage.getItem('activeNum')) || 0
  },
  mutations: {
    active(state,i) {
      state.activeNum = i,
      sessionStorage.setItem('activeNum',i)
    }
  },
  actions: {},
  modules: {},
});
