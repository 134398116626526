import Vue from 'vue'

Vue.mixin({
    methods:{
        Jump(url,active){
            this.$router.push({
                path:url,
            })
            this.$store.commit("active", active);
        }
    }
})