<template>
  <div id="app">
    <el-container v-if="$route.meta.keepAlive">
      <router-view v-if="$route.meta.keepAlive" />
    </el-container>
    <el-container v-else>
      <el-header height="90">
        <el-row type="flex" justify="center" align="middle">
          <el-col
            :span="2"
            :class="$store.state.activeNum === 0 ? 'active' : ''"
          >
            <span>
              <router-link @click.native="active(0)" to="/">首页</router-link>
            </span>
          </el-col>
          <el-col
            :span="2"
            :class="$store.state.activeNum === 1 ? 'active' : ''"
          >
            <el-dropdown>
              <span class="el-dropdown-link">
                <router-link @click.native="active(1)" to="/about"
                  >走进条子泥</router-link
                >
              </span>
              <el-dropdown-menu
                placement="top-start"
                slot="dropdown"
                @click.native="active(1)"
              >
                <el-dropdown-item @click.native="$router.push('/about')">
                  条子泥印象
                </el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/scenery')">
                  条子泥风光
                </el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/dynamic')">
                  条子泥动态
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col
            :span="2"
            :class="$store.state.activeNum === 2 ? 'active' : ''"
          >
            <el-dropdown>
              <span class="el-dropdown-link">
                <router-link @click.native="active(2)" to="/goodsActivity"
                  >玩转条子泥</router-link
                >
              </span>
              <el-dropdown-menu slot="dropdown" @click.native="active(2)">
                <el-dropdown-item
                  @click.native="$router.push('/goodsActivity')"
                >
                  精品活动
                </el-dropdown-item>
                <el-dropdown-item
                  @click.native="$router.push('/allYearActivity')"
                >
                  全年活动
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col
            :span="2"
            @click.native="active(3)"
            :class="$store.state.activeNum === 3 ? 'active' : ''"
          >
            <router-link  @click.native="active(1)" to="/virtual720"
              >虚拟条子泥</router-link
            >
          </el-col>
          <el-col :span="4">
            <!-- <span class="el-dropdown-link"> -->
            <!-- <img
                width="240"
                height="180"
                src="../static/home/header.png"
            />-->
            <!-- </span> -->
          </el-col>
          <el-col
            :span="2"
            :class="$store.state.activeNum === 4 ? 'active' : ''"
          >
            <el-dropdown>
              <span class="el-dropdown-link">
                <router-link @click.native="active(4)" to="/essential"
                  >条子泥攻略</router-link
                >
              </span>
              <el-dropdown-menu slot="dropdown" @click.native="active(4)">
                <el-dropdown-item @click.native="$router.push('/essential')">
                  基本信息
                </el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/foodList')">
                  舌尖美味
                </el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/scenicSpot')">
                  酒店住宿
                </el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/interaction')">
                  景区互动
                </el-dropdown-item>
                <el-dropdown-item @click.native="$router.push('/travel')">
                  行程推荐
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col
            :span="2"
            :class="$store.state.activeNum === 5 ? 'active' : ''"
          >
            <el-dropdown @click.native="active(5)">
              <router-link
                :to="{
                  path: '/scenicService',
                }"
              >
                <span class="el-dropdown-link">勺子商城</span>
              </router-link>
              <el-dropdown-menu slot="dropdown" @click.native="active(5)">
                <el-dropdown-item
                  @click.native="
                    $router.push({
                      path: '/scenicService',
                      query: { name: '景区服务' },
                    })
                  "
                >
                  景区服务
                </el-dropdown-item>
                <el-dropdown-item
                  @click.native="
                    $router.push({
                      path: '/scenicService',
                      query: { name: '周边服务' },
                    })
                  "
                >
                  周边服务
                </el-dropdown-item>
                <el-dropdown-item
                  @click.native="goProject('https://shop565612588.taobao.com/')"
                >
                  <!-- <span>勺子商店</span> -->
                  勺子商店
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col
            :span="2"
            @click.native="active(6)"
            :class="$store.state.activeNum === 6 ? 'active' : ''"
          >
            <el-dropdown>
              <span class="el-dropdown-link">码上游</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <img style="margin-top: 12px" src="~static/qr.png" alt="" />
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <span>小程序二维码</span> -->
            <!-- <img src="../static/qr.png" alt=""> -->
          </el-col>
          <el-col
            style="line-height: 26px"
            :span="2"
            @click.native="active(7)"
            :class="$store.state.activeNum === 7 ? 'active' : ''"
          >
            <el-dropdown style="text-align: center">
              <span
                class="el-dropdown-link"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                网页语种<br />language<i
                  class="el-icon-arrow-down el-icon--right"
                ></i
              ></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>中文</el-dropdown-item>
                <el-dropdown-item
                  @click.native="
                    goProject($router.resolve({ path: '/english' }).href)
                  "
                  >ENGLISH</el-dropdown-item
                >
                <el-dropdown-item  @click.native="
                    goProject($router.resolve({ path: '/Japanese' }).href)
                  ">日本語</el-dropdown-item>
                <el-dropdown-item
                  @click.native="
                    goProject($router.resolve({ path: '/Korean' }).href)
                  "
                  >한국어</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>

        <!-- <div id="nav">
          <router-link to="/">Home</router-link>|
          <router-link to="/about">About</router-link>
        </div>-->
        <img width="240" height="180" src="../static/home/header.png" />
        <!-- <el-dropdown class="language"  style="text-align: center;">
          <span class="el-dropdown-link">
              网页语种<br>language<i class="el-icon-arrow-down el-icon--right"></i
          ></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>中文</el-dropdown-item>
            <el-dropdown-item>ENGLISH</el-dropdown-item>
            <el-dropdown-item>日本語</el-dropdown-item>
            <el-dropdown-item>한국어</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </el-header>
      <el-main ref="scroll" style="padding: 0; overflow-y: scroll">
        <router-view />
        <el-backtop :visibility-height="200">
          <i class="el-icon-caret-top"></i>
        </el-backtop>
      </el-main>
      <el-footer style="line-height:50px;white-space: nowrap;">
        <el-row type="flex" justify="space-around" align="middle" :gutter="2">
          <el-col :span="1"  @click.native="goProject($router.resolve({ path: '/dynamic' }).href)">最新动态</el-col>
          <!-- <el-col :span="2">联系我们</el-col> -->
          <el-col :span="2" @click.native="goProject($router.resolve({ path: '/virtual720' }).href)">虚拟景区</el-col>
          <!-- <el-col :span="2">景区统计</el-col> -->
          <!-- <el-col :span="2">旅游法规</el-col> -->
          <el-col :span="3">咨询电话： 0515-85680999</el-col>
          <el-col :span="3">急救电话： 0515-85680996</el-col>
          <el-col :span="3">投诉电话： 0515-85680998</el-col>
          <el-col :span="3">东台条子泥景区管理有限公司</el-col>
          <el-col :span="4" @click.native="goProject('https://beian.miit.gov.cn/#/Integrated/index')">备案号：<img src="http://www.smslyw.com/sms1/img/bah.png" alt="" style="width: 1vw;vertical-align: middle;"> 苏ICP备2021024284号</el-col>
          <!-- <el-col style="padding-top: 8px" :span="2">
            <img
              width="34"
              height="34"
              src="http://117.80.146.163:18080/tznmh/image/wx1.png"
              alt
            />
            <img
              width="34"
              height="34"
              src="http://117.80.146.163:18080/tznmh/image/wx2.png"
              alt
              style="margin-left: 10px"
            />
          </el-col> -->
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goProject(src) {
      window.open(src, "_blank");
    },
    active(i) {
      this.$store.commit("active", i);
    },
  },
  watch: {
    // 控制滚动条位置
    $route: function (to, from) {
      // console.log(this.$refs['scroll'].wrap.scrollTop);
      if (to.meta.keepAlive) {
        // this.$refs['scroll'].wrap.scrollTop = 0
        document.body.scrollTop = to.meta.scrollTop;
        document.documentElement.scrollTop = to.meta.scrollTop;
        // document.documentElement.scrollTop = 400
      }
    },
  },
};
</script>
<style>
.Aboutbg{
  width: 392px;
  height: 282px;
}
.hr-more, .tabs > div,.morebg{
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.top-title,.title-top{
  font-family: 'tzn-font-pmzd';
}
.Lfloat{
    background-size: cover !important;
    left: -2% !important;
    transform: scale(.8);
}
.Lfloat a{
  background-size: cover !important;
}
.el-col:hover{
  color: #509ab1;
}
* {
  padding: 0;
  margin: 0;
}
hr {
  height: 1px;
  border: none;
  background: darkgray;
}
a {
  text-decoration: none;
  color: #fff;
}
.el-dropdown-menu__item {
  white-space: nowrap;
}
.router-link-active {
  text-decoration: none;
}
.el-header {
  /* margin-top: 18px; */
  background-image: url("../static/home/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  position: relative;
  z-index: 999;
  /* line-height: 60px; */
}
.el-dropdown-menu {
  position: relative;
  z-index: 9999;
  border: 1px solid #000 !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.el-dropdown-menu__item {
  color: #fff !important;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:hover {
  background-color: #000 !important;
}

.el-header > .el-row {
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  height: 90px;
  color: #fff;
}
.el-header > img {
  width: 240px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.el-header > .language {
  position: absolute;
  top: 36px;
  right: 36px;
}
/* .el-col-3{
  position: relative;
} */
.el-footer {
  background-color: #000000be;
  color: #fff;
  padding: 0 !important;
  /* line-height: 50px; */
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-col-2 {
  text-align: center;
}
.el-header .el-col-2 {
  line-height: 64px;
}
.active {
  background-image: url("../static/headActive.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
#app,
.el-container {
  /* height: 100%; */
  margin: 0;
  padding: 0;
}
body {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 1920px;
  max-width: 100%;
  min-width: 1440px !important;
  min-height: 100% !important; 
  background-image: url("../static/bodyBg.png");
}
#app {
  overflow-x: hidden;
}
/* 滚动条隐藏 */
main.el-main::-webkit-scrollbar {
  width: 0 !important;
}
</style>
